<template>
  <div class="page-wrapper">
    <b-card no-body>
      <b-tabs card>
        <span v-if="error" class="error">{{ error }}</span>
        <b-tab title="Account" active>
          <b-form>
            <inline-form-row label="Username" type="text" :disabled="!this.isNew" v-model="user.username"
              :errors="errors['username']" />
            <inline-form-row label="Email" type="text" v-model="user.email" :errors="errors['email'] " />
            <inline-form-row label="Role" _type="b-form-select" :options="this.roles" v-model="user.role"
              :errors="errors['role']" />
            <inline-form-row label="Password" type="password" v-model="user.password" :errors="errors['password']" />
            <inline-form-row label="First name" type="text" v-model="user.firstname" :errors="errors['firstname']" />
            <inline-form-row label="Last name" type="text" v-model="user.lastname" :errors="errors['lastname']" />
            <inline-form-row label="Phone" type="text" v-model="user.phone" :errors="errors['phone']" />
            <inline-form-row label="Start Date" type="date" v-model="user.start_date" :errors="errors['start_date']" />
          </b-form>
        </b-tab>
        <b-tab title="Personal">
          <b-form>
            <inline-form-row label="Emergency Phone Number" type="text" v-model="user.emergency_phone"
              :errors="errors['emergency_phone']" />
            <inline-form-row label="Contact Type" type="text" v-model="user.contact_type"
              :errors="errors['contact_type']" />
            <inline-form-row label="Contact Name" type="text" v-model="user.contact_name"
              :errors="errors['contact_name']" />
            <inline-form-row label="Personal email" type="text" v-model="user.personal_email"
              :errors="errors['emergency_phone']" />
            <inline-form-row label="Birth Date" type="date" v-model="user.birth_date" :errors="errors['birth_date']" />
            <BMSAddress v-model="user.address" />
            <inline-form-row label="Gender" _type="b-form-select" :options="options" v-model="user.gender"
              :errors="errors['gender']" />
          </b-form>
        </b-tab>
        <b-tab title="Audit Trail" disabled />
      </b-tabs>
    </b-card>
    <div class="text-right">
        <b-button @click="onSave()" variant="primary" class="m-1">
            Save
        </b-button>
    </div>
 </div>
</template>

<script>
import { getAllRoles } from '@/api/roles.api'
import { getMe, updateMe } from '@/api/users.api'
import InlineFormRow from '@/components/InlineFormRow'
import crudMixin from '@/api/crud'

export default {
  mixins: [crudMixin],
  data() {
    return {
      roles: [],
      user: {}
    }
  },
  mounted() {
    this.getMe();
    this.getRoles();
  },
  methods: {
    getRoles() {
      this.call(
        getAllRoles(),
        (response) => {
          response.data.roles.forEach(role => {
            this.roles.push(role)
          })
        }
      )
    },
    getMe() {
        this.call(
            getMe(),
            (res) => {
                this.user = res.data.user
            }
        )
    },
    onSave: function() {
        this.saveObject(
            updateMe(this.user),
            () => {
                this.user.password = ''
            }
        )
    }
  },
  components: {
        InlineFormRow,
  }
}

</script>
